import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Pagination, Box, Paper, Chip, CircularProgress, Stack } from '@mui/material';
import axios from 'axios';
import Card from './Card'; // Assuming Card component is in the same directory
import { API_BASE_URL } from './Config'; // Import the base URL

const DashboardPage = () => {
  const [cards, setCards] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const cardsPerPage = 10;

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/get-parent-docs/`);
        const fetchedCards = response.data.map(card => ({
          id: card.id,
          title: card.url,
          progress: card.status,
          tags: card.tags,
          halt_status: card.halt_status
        }));
        setCards(fetchedCards);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cards:", error);
        setLoading(false);
      }
    };
    fetchCards();
  }, []);

  const pageCount = Math.ceil(cards.length / cardsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const displayCards = cards.slice((page - 1) * cardsPerPage, page * cardsPerPage);

  return (
    <Container maxWidth="lg">
      <Paper elevation={6} sx={{ padding: 4, backgroundColor: '#e3f2fd', borderRadius: '15px' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', color: '#1976d2' }}>
          Dashboard Overview
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'center', color: '#455a64' }}>
          A quick look at the current status of your documents and progress.
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress color="primary" size={60} />
          </Box>
        ) : (
          <>
            {cards.length > 0 ? (
              <>
                <Grid container spacing={3} sx={{ marginTop: 2 }}>
                  {displayCards.map((card) => (
                    <Grid item xs={12} sm={6} md={4} key={card.id}>
                      <Card id={card.id} title={card.title} progress={card.progress} tags={card.tags} halt_status={card.halt_status} />
                    </Grid>
                  ))}
                </Grid>
                <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                  />
                </Box>
              </>
            ) : (
              <Typography variant="h6" component="p" sx={{ textAlign: 'center', color: '#f44336' }}>
                No documents found.
              </Typography>
            )}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default DashboardPage;
